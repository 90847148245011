/** @jsxImportSource @emotion/react */

import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

//test
// Note: You can change "images" to whatever you'd like.

const gqlFluid = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, quality: 85, pngCompressionSpeed: 3) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const gqlFixed = gqlFluid;

// const gqlFixed = graphql`
//   query {
//     images: allFile {
//       edges {
//         node {
//           relativePath
//           name
//           publicURL
//           childImageSharp {
//             fixed(width: 1920, quality: 85, pngCompressionSpeed: 3) {
//               ...GatsbyImageSharpFixed_withWebp
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const FileResolver = (props: {
  filename: string;
  gqlFixed?: boolean;
  children?: (file: {
    node: {
      name: string;
      publicURL: string;
      relativePath: string;
      childImageSharp: any;
    };
  }) => any;
}) => (
  <StaticQuery
    query={props.gqlFixed ? gqlFixed : gqlFluid}
    render={(data) => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        // console.log(321, props.filename, data.images.edges)
        return null;
      }
      return props.children!(image);
    }}
  />
);

const Image = (props: {
  filename: string;
  alt?: string;
  imageProps?: any; // GatsbyImageProps & { css: any };
  gqlFixed?: boolean;
  children?: (image: any) => any;
}) => (
  <FileResolver filename={props.filename} gqlFixed={props.gqlFixed}>
    {(image: any) => {
      return props.children ? (
        props.children(image)
      ) : (
        <Img
          alt={props.alt || ""}
          fluid={image.node.childImageSharp.fluid}
          fixed={image.node.childImageSharp.fixed}
          {...(props.imageProps || {})}
        />
      );
    }}
  </FileResolver>
);

export default Image;

//! migrate to gatsby-plugin-image
/*

import { GatsbyImage } from "gatsby-plugin-image";

query={graphql`
			query {
				images: allFile {
					edges {
						node {
							relativePath
							name
							publicURL
							childImageSharp {
								gatsbyImageData(layout: FIXED)
							}
						}
					}
				}
			}
		`}

<GatsbyImage
					alt={props.alt || ""}
					// fluid={image.node.childImageSharp.fluid}
					// image={image.node.childImageSharp.gatsbyImageData}
					image={{
						...image.node.childImageSharp.gatsbyImageData,
						width: 50,
					}}
					// css={{ borderRadius: 16, height: "100%" }}
					{...(props.imageProps || {})}
				/>
*/
