import { Box } from "@mui/system";
import React from "react";

import SEO from "../components/old/seo";
import { BodyPadding } from "../components/shared/body_utils";
import Footer from "../components/shared/Footer/Footer";

const CookiesPage = () => (
  <React.Fragment>
    <SEO title="Cookie Policy" />
    <BodyPadding>
      <Box
        className="page-title-section"
        sx={{
          "h1, h2, h3": {
            marginTop: "1.5em",
          },
        }}
      >
        <h1>Cookie Policy</h1>
        <br />
        <div className="cookie-container">
          <h3>What are cookies?</h3>
          <p>
            Cookies are small text files containing a string of characters that
            can be placed on your computer or mobile device that uniquely
            identify your browser or device.
          </p>
          <h3>What are cookies used for?</h3>
          <p>
            Cookies allow a site or services to know if your computer or device
            has visited that site or service before. Cookies can then be used to
            help understand how the site or service is being used, help you
            navigate between pages efficiently, help remember your preferences,
            and generally improve your browsing experience. Cookies can also
            help ensure marketing you see online is more relevant to you and
            your interests.
          </p>
          <h3>The cookies we set</h3>
          <p>
            <ol>
              <li>
                <h4>Strictly necessary cookies</h4>
                <p>
                  These cookies are essential, as they enable you to move around
                  the Service and use its features, such as accessing logged in
                  or secure areas.
                </p>
              </li>
            </ol>
          </p>
          <h3>Third party cookies</h3>
          <p>
            <ol>
              <li>
                <h4>Third party websites and social networks</h4>
                <p>
                  If you click on a hyperlink from our site to any third party
                  websites (e.g. if you ‘share’ content from This’s website with
                  friends or colleagues through social networks), you may be
                  sent cookies from these third party websites. Third party
                  websites will have their own privacy and cookie policies which
                  we cannot control. Please check the third-party websites for
                  more information about their cookies and how to manage them.
                </p>
              </li>
              <li>
                <h4>Google Analytics</h4>
                <p>
                  Google Analytics is a service aimed at analyzing web surfing
                  data, provided by Google Inc., with the purpose to collect
                  personal data to monitor and analyze how the website is used.
                  This type of analysis is carried out to improve the navigation
                  experience more and make the site more useful and usable by
                  the user. The reports, the result of this analysis can / can
                  not be shared with other similar services developed by Google
                  (if so, refer to the detail of these products). Google
                  Analitycs has been appointed responsible for the processing of
                  personal data on behalf of the owner (owner of the site), with
                  adequate guarantees of data security and availability of the
                  same. The data collected by Google Analitycs are:
                </p>
                <p>
                  <ul>
                    <li>Number of users</li>
                    <li>Sessions</li>
                    <li>Rebound frequency</li>
                    <li>Duration of the session</li>
                    <li>Active users at this time</li>
                    <li>User behavior over time</li>
                    <li>Frequency of use</li>
                    <li>Position of users</li>
                    <li>Type of user device</li>
                    <li>Pages visited by users</li>
                    <li>Demographic data</li>
                    <li>Geographical data</li>
                    <li>Browser and operating system used</li>
                    <li>Site performance</li>
                    <li>Conversions</li>
                  </ul>
                </p>
                <p>
                  To disable the Google Analitycs cookie, go to the following{" "}
                  <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=it">
                    link
                  </a>
                </p>
              </li>
              <li>
                <h4>Hotjar</h4>
                <p>
                  We use <a href="https://www.hotjar.com/">Hotjar</a> in order
                  to better understand our users’ needs and to optimize this
                  service and experience. Hotjar is a technology service that
                  helps us better understand our users experience (e.g. how much
                  time they spend on which pages, which links they choose to
                  click, what users do and don’t like, etc.) and this enables us
                  to build and maintain our service with user feedback. Hotjar
                  uses cookies and other technologies to collect data on our
                  users’ behavior and their devices (in particular device’s IP
                  address (captured and stored only in anonymized form), device
                  screen size, device type (unique device identifiers), browser
                  information, geographic location (country only), preferred
                  language used to display our website). Hotjar stores this
                  information in a pseudonymized user profile. Neither Hotjar
                  nor we will ever use this information to identify individual
                  users or to match it with further data on an individual user.
                  For further details, please see Hotjar’s privacy policy by
                  clicking on this{" "}
                  <a href="https://www.hotjar.com/legal/policies/privacy">
                    link
                  </a>
                  .You can opt-out to the creation of a user profile, Hotjar’s
                  storing of data about your usage of our site and Hotjar’s use
                  of tracking cookies on other websites by following this{" "}
                  <a href="https://www.hotjar.com/legal/compliance/opt-out">
                    opt-out link
                  </a>
                  .
                </p>
              </li>
            </ol>
          </p>
          <h3>Disabling cookies</h3>
          <p>
            <ol>
              <li>
                <h4>Warning</h4>
                <p>
                  You can prevent the setting of cookies by adjusting the
                  settings on your browser. Be aware that disabling cookies will
                  reduce the functionality of this and many other websites that
                  you visit so that some features may not work. Therefore it is
                  recommended that you do not disable cookies.{" "}
                </p>
              </li>
              <li>
                <h4>How to control and delete cookies</h4>
                <p>
                  If you want to delete cookies follow{" "}
                  <a href="https://www.wikihow.com/Clear-Your-Browser%27s-Cookies">
                    this instructions
                  </a>
                  . If you wish to disable your browser from receiving cookies
                  follow this instructions. Note that if you set your browser to
                  disable cookies, you may not be able to access certain parts
                  of our Service and other parts of our Service may not work
                  properly. You can find out more information cookie settings at
                  third-party information sites, such as{" "}
                  <a href="https://www.allaboutcookies.org/">
                    allaboutcookies.org
                  </a>
                  .
                </p>
              </li>
            </ol>
          </p>
        </div>
      </Box>
    </BodyPadding>
    {/* <DarkSide>
      <BodyPadding>
        <FooterContent withoutTitle />
      </BodyPadding>
    </DarkSide> */}
    <Footer />
  </React.Fragment>
);

export default CookiesPage;
