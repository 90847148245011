/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { Box } from "@mui/material";
import Image from "../image";
import { ress } from "./mtext";

export const BodyPadding = (props: {
	className?: string;
	children: React.ReactNode;
	horizontal?: boolean;
	vertical?: boolean;
	noAutoMargin?: boolean;
	containerCss?: CSSObject;
}) => {
	const v = props.horizontal ? 0 : 1;
	const h = props.vertical ? 0 : 1;
	const containerCss = props.containerCss ?? {};
	return (
		<div
			className={props.className}
			children={props.children}
			css={{
				margin: props.noAutoMargin ? "inherit" : "auto",
				maxWidth: 1750, // 1920,
				padding: `${v * 48}px ${h * 96}px`,
				position: "relative",
				...ress("md", {
					padding: `${v * 24}px ${h * 48}px`,
				}),
				...ress("xs", {
					padding: `${v * 12}px ${h * 20}px`,
				}),
				...containerCss,
			}}
		/>
	);
};

export const BodyImage = (props: { image: string; maxWidth?: number }) => (
	<BodyPadding horizontal>
		<Box display="flex" flexDirection="column" alignItems="center">
			<Image
				filename={props.image}
				imageProps={{
					css: { width: "100%", maxWidth: props.maxWidth || 1200 },
				}}
			/>
		</Box>
	</BodyPadding>
);
